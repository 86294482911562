import React from "react"
import SEO from "../../components/SEO"
import { graphql } from "gatsby"
import Footer from "../../components/footer"
import { ProductNav } from "../../components/header/Header"
import "../../styles/bmc.less"
import Map from "../../components/map"
import { ConsultationPopButton } from "../../components/form-biz"
import Drop from "../../components/drop"
import { SolutionTopBanner } from "../../components/block"
import { Action } from "./edge-data-center-services"
import { lang } from "../../utils"
import { Button } from "../../components/ui"
const BMCTable = require(`../../assets/bmc-server-${lang}.svg`)
const loadText = require("src/utils").loadText
const url_data = loadText("url")
const static_data = loadText("bmc")
import {
  AnimationWrap,
  ProductSupport,
  ProductBlog,
} from "../../components/block"

const navigate = url => window.open(url)
import newProductMap from "../../utils/newProductsMap"
const KeyFeatures = () => {
  return (
    <div className="bmc-keyFeatures">
      <div>
        <div className="bmc-keyFeatures-block1">
          <AnimationWrap>
            <div className="bmc-t1">{static_data.feature[0].title}</div>
            <div
              className="font-20 text-center mt-24 block-middle"
              style={{ maxWidth: "1016px" }}
            >
              {static_data.feature[0].description.map(d => (
                <div>{d}</div>
              ))}
            </div>
          </AnimationWrap>
        </div>
        <div className="bmc-keyFeatures-block2-container">
          <Drop
            color="#fff"
            style={
              {
                // bottom: "-50px",
              }
            }
          />
          <AnimationWrap>
            <div className="bmc-keyFeatures-block2 full-bg">
              <div className="bmc-keyFeatures-block2_left">
                <div className="imgContainer">
                  <img
                    src="/products/bmc/bmc-b2-icon.svg"
                    alt="console"
                    className="bmc-keyFeatures-block2-console"
                  ></img>
                </div>
                <img
                  src="/products/bmc/bmc-b2-man.png"
                  alt="human"
                  className="bmc-keyFeatures-block2-human"
                />
              </div>
              <div className="bmc-keyFeatures-block2_right ">
                <div className="bmc-t1 title">
                  {static_data.feature[1].title}
                </div>
                <div className="font-20 mt-24 content">
                  {static_data.feature[1].description[0]}
                </div>
              </div>
            </div>
          </AnimationWrap>
        </div>
      </div>

      <HybridClouds />

      <ProductSupport
        source="bmc"
        bgPic="/products/bmc/bmc-b4-bg.svg"
        woman="/products/bmc/bmc-b4-woman.png"
        bgColor="linear-gradient(97deg, rgba(246, 247, 254, 1) 0%,rgba(231, 230, 255, 1) 100%)"
      />
    </div>
  )
}
export const IconGroups = () => {
  return (
    <div className="mt-40 bmc-keyFeatures-block3-iconWrapper">
      <div>
        <div className="title">{static_data.feature[2].cloudLogo}</div>
        <div className="icons">
          {(lang === "en"
            ? ["AWS", "Azure", "Google", "Alibaba", "UCloud", "Tencent"]
            : ["AWS", "Azure", "Alibaba", "UCloud", "Tencent"]
          ).map(key => (
            <div key={key} className="bmc-keyFeatures-block3-icon">
              <img src={`/products/bmc/${key}.svg`} alt={key}></img>
              <div className="font-16 mt-8 name">{key}</div>
            </div>
          ))}
        </div>
      </div>
      <div>
        {/* <div className="title">{static_data.feature[2].interconnectLogo}</div>

        <div className="icons">
          {(lang === "en" ? ["Equinix", "Megaport", "Coresite"] : []).map(
            key => (
              <div key={key} className="bmc-keyFeatures-block3-icon">
                <img src={`/products/bmc/${key}.svg`} alt={key}></img>
                <div className="font-16 mt-8">{key}</div>
              </div>
            )
          )}
        </div> */}
      </div>
    </div>
  )
}
const HybridClouds = () => (
  <div className="bmc-keyFeatures-block3-outterBg">
    <div className="bmc-keyFeatures-block3-container">
      <div className="bmc-keyFeatures-block3 full-bg">
        <AnimationWrap>
          <div className="bmc-t1 mobile-padding" style={{ color: "#fff" }}>
            {static_data.feature[2].title}
          </div>
          <div
            className="font-20 text-center mobile-padding mt-24 block-middle"
            style={{ maxWidth: "941px" }}
          >
            {static_data.feature[2].description[0]}
          </div>
          <IconGroups />
        </AnimationWrap>
      </div>
    </div>
  </div>
)
export const MapContainer = ({ title, description, defaultSelected }) => {
  return (
    <div className="bmc-map">
      <AnimationWrap>
        <div className="bmc-t1 mobile-padding">{title}</div>
        <div
          className="font-20 block-middle text-center mobile-padding mt-24 block-middle description"
          style={{ maxWidth: "981px" }}
        >
          {description}
        </div>
      </AnimationWrap>
      <div className="mt-56">
        <Map defaultSelected={defaultSelected} />
      </div>
    </div>
  )
}
const Reason = () => {
  return (
    <div className="bmc-reason">
      <AnimationWrap>
        <div className="font-36 text-center semi-bold t-color mobile-padding">
          {static_data.reason.title}
        </div>
        <div
          className="font-20 text-center mobile-padding mt-24 block-middle"
          style={{ maxWidth: "981px" }}
        >
          {static_data.reason.description}
        </div>
        <div className="bmc-reason-feature">
          {/* <BMCSolution /> */}
          <picture>
            <source
              srcSet={`/products/bmc/solution-${lang}-m.png 640w `}
              media="(max-width: 640px)"
            />
            <img
              src={`/products/bmc/solution-${lang}.png`}
              alt="bmc-solution"
              className="mobile-padding"
            />
          </picture>
        </div>
        <div className="mobile-padding">
          <div className="bmc-reason-badge full-bg font-20 mobile-padding">
            <div>{static_data.reason.bannerText}</div>
          </div>
        </div>
      </AnimationWrap>
    </div>
  )
}
export const Feedback = data => {
  const {
    companyPreview,
    companyName,
    logo,
    customerName,
    content,
    customerTitle,
  } = data
  // console.log(data)

  return data.logo ? (
    <AnimationWrap>
      <div className="bmc-feedback">
        <div className="font-36 text-center semi-bold t-color mobile-padding">
          {static_data.feedback.title}
        </div>
        <div className="bmc-feedback-quoteContainer">
          <div className="bmc-feedback-quote" style={{ background: "#f3f5f9" }}>
            <div className="font-20 semi-bold t-color companyName">
              {companyName}
            </div>
            <div className="bmc-feedback-quoteContent font-16 t-color">
              {content}
            </div>
            <div className="bmc-feedback-quote-author">
              <div className="font-16 semi-bold t-color">{customerName}</div>
              <div className="bmc-feedback-quote-authorTitle mt-8">
                {customerTitle}
              </div>
            </div>
            <img
              src={logo.localFile.publicURL}
              alt={companyName}
              className="bmc-feedback-quote-companyLogo"
            />
          </div>
          <img
            src={(companyPreview.localFile || {}).publicURL}
            alt={`${companyName}-preview`}
            className="bmc-feedback-companyIcon"
          />
        </div>
      </div>
    </AnimationWrap>
  ) : null
}
const Server = () => {
  return (
    <div className="bmc-server">
      <AnimationWrap>
        <div className="font-36 semi-bold t-color">
          {static_data.server.title}
        </div>
        <div
          style={{ maxWidth: "1224px" }}
          className="block-middle mt-56 only-desktop"
        >
          <BMCTable />
        </div>
        <div className="only-mobile bmc-server-detail">
          {["s", "m", "l"].map(size => (
            <img
              src={`/products/bmc/server-${lang}-${size}.png`}
              alt=""
              style={{ width: "554px" }}
            />
          ))}
        </div>
        {/* <img
        src="/products/bmc/server.svg"
        alt={static_data.server.title}
        className="mt-48"
      /> */}
        {/* <div></div> */}
        <div className="mt-64 btn">
          <ConsultationPopButton buttonWidth={476} source="bmc">
            {static_data.server.buttonText}
          </ConsultationPopButton>
        </div>
      </AnimationWrap>
    </div>
  )
}

// const TopBanner = () => (
//   <div className="bmc-topContainer">
//     <div className="bmc-topWrapper">
//       <Header logo="/logo-blue.png" />
//       <div className="bmc-intro">
//         <div className="bmc-intro-content">
//           <div className="font-56 semi-bold t-color">
//             {static_data.topBanner.title}
//           </div>
//           <div className="font-28 mt-16">{static_data.topBanner.subtitle}</div>
//           <div className="mt-24 font-20">
//             {static_data.topBanner.description}
//           </div>
//           <div className="mt-36">
//             <ConsultationPopButton buttonWidth={208} sour>
//               {static_data.topBanner.buttonText}
//             </ConsultationPopButton>
//           </div>
//         </div>
//       </div>
//     </div>
//   </div>
// )
const Os = () => {
  return (
    <div className="bmc-os">
      <div className="bmc-os-imgContainer full-bg">
        <AnimationWrap>
          <div className="bmc-osContent page-middle">
            <div className="font-36 semi-bold mobile-padding">
              {static_data.os.title}
            </div>
            <div
              className="font-20 text-center mt-24 block-middle mobile-padding"
              style={{ maxWidth: "600px" }}
            >
              {static_data.os.description}
            </div>
            <div className="bmc-os-itemContainer">
              {static_data.os.type.map((d, idx) => (
                <div key={d} className="bmc-os-item">
                  <img src={`/products/bmc/os${idx + 1}.svg`} alt="d" />
                  <div className="font-20 mt-8">{d}</div>
                </div>
              ))}
            </div>
          </div>
        </AnimationWrap>
      </div>
      <Drop color="rgb(147,151,237)" />
    </div>
  )
}
export const Solution = ({
  title,
  description,
  style = {},
  hideMap = {},
}: {
  title: string
  description: string
  hideMap?: { [key: string]: boolean }
  style?: React.CSSProperties
}) => {
  return (
    <div className="bmc-solution " style={style}>
      <div className="page-middle">
        <AnimationWrap>
          <div className="bmc-sectionTitle mobile-padding">{title}</div>
          <div className="font-20 text-center mt-24 mobile-padding description block-middle">
            {description}
          </div>
          <div className="bmc-solution-itemContainer">
            {static_data.solution.content
              .filter(({ _key }) => !hideMap[_key])
              .map(({ name, description, _key }, idx) => (
                <div
                  className="bmc-solution-item"
                  onClick={() => navigate(url_data[_key].link)}
                >
                  <img
                    src={`/header/${_key}.svg`}
                    alt={name}
                    style={{ width: "110px" }}
                  />
                  <div className="font-16 title semi-bold mt-16">{name}</div>
                  <div className="mt-8 description">{description}</div>
                  {newProductMap[_key] && (
                    <img
                      src={"/new-badge.svg"}
                      className="new-badge1"
                      alt="new-product"
                    />
                  )}
                </div>
              ))}
          </div>
        </AnimationWrap>
      </div>
    </div>
  )
}
export default function ({ data }) {
  const { allStrapiPosts, strapiTestimonials } = data
  return (
    <div className="bmc">
      <SEO {...static_data.seo} featuredImage="/featuredImage/bmc.jpg" />
      {/* <TopBanner /> */}
      <SolutionTopBanner
        {...static_data.topBanner}
        backgroundAnimated={true}
        bgColor="linear-gradient(180deg,rgba(246, 247, 254, 1) 0%,rgba(231, 230, 255, 1) 100%)"
        bgPic="/products/bmc/topBanner-bg.svg"
        animatedIcon="/products/bmc/animatedIcon.svg"
        iconBg="/products/bmc/iconBg.svg"
        source="bmc"
        // extraButton={
        //   <Button
        //     // buttonlassName="start-btn"
        //     size="l"
        //     className="white"
        //     onClick={() => {
        //       window.open("https://console.zenlayer.com/auth/cen-login")
        //     }}
        //   >
        //     {static_data.topBanner.buttonText2}
        //   </Button>
        // }
        bannerPic={{
          src: `/products/bmc/topBanner-bg-m.svg`,
          style: {
            backgroundPosition: "top center",
          },
        }}
        // bannerPic="/products/bmc/topBanner-bg-m.svg"
      />
      {/* <ProductNav
        name={static_data.bmc}
        menus={static_data.navigation}
        minWidth={1100}
      />
      <a id="overview" />
      <KeyFeatures />
      <div style={{ position: "absolute", left: "100000px" }}>
        <BMCTable />
      </div>

      <a id="locations" />
      <MapContainer
        title={static_data.map.title}
        description={static_data.map.description}
        defaultSelected={[url_data.bmc.name]}
      />
      <a id="hybridClouds" />
      <a id="hybridClouds" />
      <HybridClouds />
      <a id="reason" />
      <Reason />
      <a id="testimonials" />
      <Feedback {...strapiTestimonials} />
      <ProductAction
        bgColor="linear-gradient(180deg,rgba(246, 247, 254, 1) 0%,rgba(231, 230, 255, 1) 100%)"
        bgPic="/products/bmc/action-bg.svg"
        source="bmc"
        title={static_data.action.title}
        buttonText={static_data.action.buttonText}
        content={static_data.action.content.map((i, idx) => ({
          description: i,
          src: `/products/bmc/action${idx + 1}.png`,
        }))}
      />
      <Action
        pic={`/products/bmc/action.png`}
        picM={`/products/bmc/action-m.png`}
        title={static_data.action.title}
        description={static_data.action.description}
        buttonText={static_data.action.buttonText}
        onClick={() => {
          navigate("/customer-story/gaming")
        }}
      />
      <a id="configurations" />
      <Server />
      <Os />

      <Solution
        title={static_data.solution.title}
        description={static_data.solution.description}
      />
      <a id="learnMore" />
      <ProductBlog
        title={static_data.blog.title}
        blogs={allStrapiPosts.edges.map(({ node }) => node)}
      /> */}
      <Footer />
    </div>
  )
}
export const query = graphql`
  {
    allStrapiPosts(
      filter: {
        categories: { elemMatch: { name: { eq: "Bare Metal Cloud" } } }
        author: {}
      }
    ) {
      edges {
        node {
          slug
          categories {
            name
          }
          wp_date
          created_at
          content
          title
          thumbnail {
            localFile {
              publicURL
            }
          }
          author {
            slug
            name
          }
          excerpt
        }
      }
    }
    strapiTestimonials(inPage: { eq: "bmc" }) {
      customerTitle
      customerName
      content
      companyPreview {
        localFile {
          publicURL
        }
      }
      logo {
        localFile {
          publicURL
        }
      }
      companyName
    }
  }
`
